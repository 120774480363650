import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { magic } from '@/contexts/magic'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export default function useLogin() {
  const router = useRouter()
  const {setUser} = useAuth() as AuthContextType
  const methods = useForm({
    defaultValues: {
      signInEmail: '',
    },
    mode: 'onChange',
  })
  const [loadingLogin, setLoadingLogin] = useState<boolean>(false)

  const { watch } = methods
  const email = watch('signInEmail')

  const handleLogin = async (e: any) => {
    e.preventDefault()
    setLoadingLogin(true)

    // Log in using our email with Magic and store the returned DID token in a variable
    try {
      const didToken = await magic.auth.loginWithMagicLink({
        email
      })

      // Send this token to our validation endpoint
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${didToken}`,
        },
      })

      // If successful, update our user state with their metadata and route to the dashboard
      if (res.ok) {
        const userToken = await magic.user.getIdToken()
        const user = await magic.user.getMetadata()
        const isLoggedIn = await magic.user.isLoggedIn()
        setUser({
          user: {
            userEmail: user.email,
            isValid: isLoggedIn,
            userToken: userToken,
            userId: user.issuer,
          },
        })
        router.push('/')
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    handleLogin,
    methods,
    loadingLogin
  }
}
