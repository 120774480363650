import { ReactComponent as MempoolsLogo } from '@/assets/icons/mempools-logo-short.svg'
import FormInput from '@/common/components/FormInput/FormInput'
import useLogin from '@/common/hooks/useLogin'
import { colors } from '@/styles/colorScheme'
import { typography } from '@/styles/typography'
import { Box, Button, CircularProgress, styled, Typography } from '@mui/material'
import { FormProvider } from 'react-hook-form'

export default function Login() {
  const { methods, handleLogin, loadingLogin } = useLogin()

  return (
    <Box
      sx={{
        backgroundColor: colors.black,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Gradient />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box
          sx={{
            width: '380px',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <MempoolsLogo />
            <Typography fontSize="31px" fontWeight={600} color="#FFFFFF">
              Welcome
            </Typography>
            <Typography fontSize="16px" fontWeight={400} color="rgba(255, 255, 255, 0.48)"
            sx={{
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
            >
              Enter your email address to continue
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={handleLogin}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '@media (max-width: 600px)': {
                    width: '80%',
                    margin: '0 auto',
                  },
                }}
              >
                <FormInput name="signInEmail" placeholder="Email address" />
                <StyledButton type="submit">
                  Continue{' '}
                  {loadingLogin && (
                    <CircularProgress
                      sx={{
                        width: '15px !important',
                        height: '15px !important',
                        color: '#000',
                      }}
                    />
                  )}
                </StyledButton>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </Box>
  )
}

const Gradient = styled('div')(() => ({
  background: 'radial-gradient(110.86% 100% at 50% 0%, #275D40 10%, #2A1533 49.33%, #000000 100%)',
  transform: 'matrix(1, 0, 0, -1, 0, 0)',
  position: 'absolute',
  width: '100%',
  height: '100vh',
  left: '0px',
}))

export const StyledButton = styled(Button)(() => ({
  width: '100%',
  backgroundColor: colors.green.primary,
  color: colors.black,
  padding: '8px 16px',
  gap: '10px',
  ...typography.monoBig,
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
  },
  '&:hover': {
    backgroundColor: '#4EBA7F',
  },
}))
